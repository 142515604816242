import { customerId } from './tiersCreation'
import { getAllCustomers } from './index'
import { customerApi } from '~/src/services/customerApi'
import { useAlert } from '~/src/composables/core/useNotification'

const CustomerData = ref({} as any)

// eslint-disable-next-line import/no-mutable-exports
export let fetchCustomerDetails: (id: string) => void

export const blacklistCustomer = () => {
  const { fetchCustomers } = getAllCustomers()

  const loading = ref(false)
  const count = ref(0)
  const CustomerStatus = computed({
    get: () => !CustomerData.value.active,
    set: (val) => {
      if (count.value === 0 && !CustomerData.value.active) return count.value++
      blacklistCustomer(val)
    },
  })

  const blacklistCustomer = async (status: any) => {
    loading.value = true

    const res = (await customerApi.$_blacklistCustomer(CustomerData.value.id, { blacklist: status })) as any
    loading.value = false
    if (res !== 'ERROR') {
      useAlert().openAlert({ type: 'SUCCESS', msg: res?.data?.message })
      fetchCustomers()
    }
  }
  return { loading, CustomerStatus, CustomerData }
}

export const getCustomerAccounts = () => {
  const loading = ref(false)
  const id = useRoute().params.id
  const CustomerAccData = ref([])

  const fetchCustomerAccounts = async () => {
    loading.value = true
    if (CustomerData.value.tier !== 0) {
      const res = (await customerApi.$_getCustomerVirtualAccounts(id as string)) as any
      if (res !== 'ERROR') {
        CustomerAccData.value = res?.data?.data
      }
    }
    loading.value = false
  }
  return { fetchCustomerAccounts, loading, CustomerAccData }
}
export const getCustomerTransactions = () => {
  const loading = ref(false)
  const id = useRoute().params.id
  const CustomerTransData = ref([])

  const fetchCustomerTransactions = async () => {
    loading.value = true
    const res = (await customerApi.$_getCustomerTransactions(id as string)) as any
    loading.value = false
    if (res !== 'ERROR') {
      CustomerTransData.value = res?.data?.data
    }
  }
  return { fetchCustomerTransactions, loading, CustomerTransData }
}
export const getCustomerCards = () => {
  const loading = ref(false)
  const id = useRoute().params.id
  const CustomerCardData = ref([])

  const fetchCustomerCards = async () => {
    loading.value = true
    const res = (await customerApi.$_getCustomerCards(id as string)) as any
    loading.value = false
    if (res !== 'ERROR') {
      CustomerCardData.value = res?.data?.data
    }
  }
  return { fetchCustomerCards, loading, CustomerCardData }
}

export const getCustomer = () => {
  const loading = ref(false)

  fetchCustomerDetails = async (id: string) => {
    loading.value = true
    const res = (await customerApi.$_getCustomer(id)) as any
    loading.value = false
    if (res !== 'ERROR') {
      CustomerData.value = res?.data?.data
    }
  }
  return { fetchCustomerDetails, loading, CustomerData, customerId }
}
