import Recase from 'better-recase'
import { AxiosResponse } from 'axios'
import { AUTH_GATEWAY_ENDPOINT } from '../../SetupBase'
import { ForgotPassword, Login, ResendVerificationEmail, ResetPassword, SignUp, VerifyEmail } from './type'
import { ServerResponse } from '~/src/types/api'

export const authApi = {
  $_register: (credential: SignUp): Promise<AxiosResponse<ServerResponse<undefined>>> | 'ERROR' => {
    const url = '/auth/register'
    return AUTH_GATEWAY_ENDPOINT.post(url, Recase.snakeCopy(credential))
  },
  $_login: (credential: Login): Promise<AxiosResponse<ServerResponse<undefined>>> | 'ERROR' => {
    const url = '/auth/login'
    return AUTH_GATEWAY_ENDPOINT.post(url, credential)
  },
  $_VerifyEmail: (credential: VerifyEmail): Promise<AxiosResponse<ServerResponse<undefined>> | 'ERROR'> => {
    const url = '/auth/verify-email'
    return AUTH_GATEWAY_ENDPOINT.patch(url, credential)
  },
  $_ResendVerification: (
    credential: ResendVerificationEmail,
  ): Promise<AxiosResponse<ServerResponse<undefined>> | 'ERROR'> => {
    const url = '/auth/resend-verify-email'
    return AUTH_GATEWAY_ENDPOINT.post(url, credential)
  },
  $_resetPassword: (credential: ResetPassword): Promise<AxiosResponse<ServerResponse<undefined>> | 'ERROR'> => {
    const url = '/auth/reset-password'
    return AUTH_GATEWAY_ENDPOINT.patch(url, credential)
  },
  $_forgotPassword: (credential: ForgotPassword): Promise<AxiosResponse<ServerResponse<undefined>> | 'ERROR'> => {
    const url = '/auth/forgot-password'
    return AUTH_GATEWAY_ENDPOINT.post(url, credential)
  },
  $_verify_otp: (credential: any) => {
    const url = '/auth/verify-otp'
    return AUTH_GATEWAY_ENDPOINT.post(url, credential)
  },
  $_confirmInvite: async (reference: string) => {
    const url = 'users/invite'
    const res = await AUTH_GATEWAY_ENDPOINT.get(`${url}/${reference}`)

    return res?.data
  },
  $_acceptInvite: async () => {
    const url = 'users/accept-invite'
    const res = await AUTH_GATEWAY_ENDPOINT.post(url)

    return res?.data
  },
}
