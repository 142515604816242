import { PermissionType } from './api'
import { RouteLocationRaw } from '#vue-router'

export interface Constraints {
  title: string
  name: string
  type: 'date' | 'select'
  options: {
    value: string
    label: string
  }[]
}

export enum CardBrand {
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA',
}

export enum ApiFeatureFlag {
  USD_ACCOUNT_CREATION = 'USD_ACCOUNT_CREATION',
  STASH = 'STASH',
}

export enum CardType {
  VIRTUAL = 'VIRTUAL',
}

export enum TransactionStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum InvestmentStatus {
  ACTIVE = 'ACTIVE',
  FAILED = 'FAILED',
  COMPLETED = 'COMPLETED',
}

export enum VirtualAccountStatus {
  New = '',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
}

export enum CurrencyCode {
  NGN = 'NGN',
  KES = 'KES',
  GHS = 'GHS',
  XAF = 'XAF',
  USD = 'USD',
  XOF = 'XOF',
  UGX = 'UGX',
  TZS = 'TZS',
}

export enum TransactionType {
  TRANSFER = 'TRANSFER',
  COLLECTION = 'COLLECTION',
  INVESTMENT = 'INVESTMENT',
}

export enum TransactionEntryType {
  DEBIT = 'DEBIT',
  CREDIT = 'CREDIT',
}

export enum UsResidencyStatus {
  NONE = '',
  US_CITIZEN = 'US_CITIZEN',
  RESIDENT_ALIEN = 'RESIDENT_ALIEN',
  NON_RESIDENT_ALIEN = 'NON_RESIDENT_ALIEN',
}

export enum UsdInstructionType {
  SWIFT = 'SWIFT',
  ACH = 'ACH',
  FEDWIRE = 'FEDWIRE',
}

export enum EmploymentStatus {
  NONE = '',
  EMPLOYEE = 'EMPLOYEE',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  UNEMPLOYED = 'UNEMPLOYED',
  OTHER = 'OTHER',
}

export enum SourceOfFunds {
  PAYSLIP = 'PAYSLIP',
  BANK_STATEMENT = 'BANK_STATEMENT',
}

export enum ProofOfAddress {
  UTILITY_BILL = 'UTILITY_BILL',
  LEASE_AGREEMENT = 'LEASE_AGREEMENT',
}

export enum CountryIso {
  GHS = 'GH',
  KES = 'KE',
  XAF = 'CM',
  NGN = 'NG',
  TZS = 'TZ',
  UGX = 'UG',
  USD = 'US', // TODO: Deprecating this option because it is invalid and causes errors as mentioned here https://wirepay.slack.com/archives/C03BAEHGG1E/p1725986716828449
}

export const MOBILE_MONEY_SUPPORTED_COUNTRIES = [
  { country: CountryIso.XAF, currency: CurrencyCode.XAF },
  { country: CountryIso.GHS, currency: CurrencyCode.GHS },
  { country: CountryIso.KES, currency: CurrencyCode.KES },
  { country: CountryIso.UGX, currency: CurrencyCode.UGX },
  { country: CountryIso.TZS, currency: CurrencyCode.TZS },
  { country: null, currency: CurrencyCode.XOF },
]

export enum TransferScheme {
  BANKACCOUNT = 'BANKACCOUNT',
  MOBILEMONEY = 'MOBILEMONEY',
  CASHPICKUP = 'CASHPICKUP',
  DOM = 'DOM',
}

export enum BillingType {
  DATA = 'data',
  AIRTIME = 'airtime',
  CABLE = 'cable',
  ELECTRICITY = 'electricity',
}

export enum Institution {
  NUBAN = 'NUBAN',
  BOG = 'BOG',
  CBK = 'CBK',
  MOMO = 'MOMO',
  MOMOCOLLECTION = 'MOMOCOLLECTION',
  CASHPICKUP = 'CASHPICKUP',
  DOM = 'DOM',
}

export enum IdentificationType {
  NationalId = 'ID',
  NationalPassport = 'PASSPORT',
}

export enum CallingCode {
  NGN = '234',
  KES = '254',
  GHS = '233',
  XAF = '237',
}

export type Permission = {
  key: string
  name: string
  description: string
  type: PermissionType
}

export enum CardStatus {
  Terminated = 'TERMINATED', // terminated
  Active = 'ACTIVE', // unFrozen
  Disabled = 'DISABLED', // Frozen
}

export enum InviteStatus {
  Invited = 'INVITED',
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED',
}

export enum DateFormat {
  lll = 'D MMM, YYYY - HH:mm a',
  Default = 'YYYY-MM-DD',
  Custom = 'Do MMM, YYYY',
  DefaultAlt = 'DD-MM-YYYY',
}

export enum BillingStatus {
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
}

export enum ComplianceRoutes {
  index = 'compliances',
  ownership = 'compliances-ownership',
  address = 'compliances-address',
  documents = 'compliances-documents',
  review = 'compliances-review',
  success = 'compliances-success',
  directorIdSuccess = 'compliances-directors-id-success',
  directorId = 'compliances-directors-id',
}

export enum Answer {
  Yes = 'yes',
  No = 'no',
}

export interface Stepper {
  value: number
  count: number
  label: string
  link?: RouteLocationRaw | undefined
  action?: (() => void) | undefined
  steps?: Omit<Stepper, 'count' | 'steps' | 'complete'>[]
  complete: boolean
}

export interface Step {
  label: string
  link?: RouteLocationRaw
  action?: () => void
  steps?: Omit<Step, 'steps'>[]
  complete: boolean
}

export enum AppLang {
  English = 'en',
}

export enum CurrencyKey {
  GH = 'GHS',
  KE = 'KES',
  CM = 'XAF',
}

export const MAX_PHONE_LENGTH = 10

export type VeeValidationProps = {
  validateEvent: boolean
  error: any
  required: any
}

export enum JOB_TYPE {
  Other = 'other',
  CEO = 'CEO',
  CTO = 'CTO',
  CFO = 'CFO',
  COO = 'COO',
  Founder = 'Founder',
  President = 'President',
  GeneralPartner = 'General Partner',
  FinanceTeam = 'Finance Team',
}

export enum RELATIONSHIP {
  SPOUSE = 'SPOUSE',
  EX_SPOUSE = 'EX_SPOUSE',
  CHILDREN = 'CHILDREN',
  PARENT = 'PARENT',
  SIBLING = 'SIBLING',
  RELATIVE = 'RELATIVE',
  SELF = 'SELF',
  FRIEND = 'FRIEND',
  BUSINESS_PARTNER = 'BUSINESS_PARTNER',
  CUSTOMER = 'CUSTOMER',
  EMPLOYEE = 'EMPLOYEE',
  BRANCH_OFFICE = 'BRANCH_OFFICE',
  SUBSIDIARY_COMPANY = 'SUBSIDIARY_COMPANY',
  HOLDING_COMPANY = 'HOLDING_COMPANY',
  SUPPLIER = 'SUPPLIER',
  CREDITOR = 'CREDITOR',
  DEBTOR = 'DEBTOR',
  FRANCHISEE = 'FRANCHISEE',
  NOT_RELATED = 'NOT_RELATED',
}

export enum TransactionChannel {
  ACH = 'ACH',
  FEDWIRE = 'FEDWIRE',
  SWIFT = 'SWIFT',
  MOMO = 'MOMO',
  AIRTIME = 'AIRTIME',
  ACCOUNT = 'ACCOUNT',
  CARD = 'CARD',
}

export enum ACCOUNT_TYPE {
  CHECKING = 'CHECKING',
  SAVING = 'SAVING',
}

export enum COMPLIANCE_STEPS_ENUM {
  CompanyInfo = 'kyc.companyInfo',
  CompanyAddress = 'kyc.companyAddress',
  OwnershipDetails = 'kyc.ownershipDetails',
  CompanyDocument = 'kyc.companyDocument',
  Review = 'kyc.review',
}

export enum USD_TRANSFER_STEPS_ENUM {
  Recipient = 'wallets.usd.recipient',
  PaymentMethod = 'wallets.usd.paymentMethod',
  Amount = 'amount',
  AdditionalInfo = 'wallets.usd.additionalInfo',
  Review = 'wallets.usd.review',
}

export enum COLLECTION_ENUM {
  Recipient = 'wallets.usd.recipient',
  Amount = 'amount',
  AdditionalInfo = 'wallets.usd.additionalInfo',
  Review = 'wallets.usd.review',
}

export enum STASH_CREATION_STEPS_ENUM {
  CreateStash = 'stash.create',
  AmountAndDuration = 'stash.amountAndDuration',
  ReviewPlan = 'stash.reviewPlan',
}
