import fileDownload from 'js-file-download'
import { useCardsModal } from '~/src/composables/core/modals'
import { useAlert } from '~/src/composables/core/useNotification'
import { cardApi } from '~/src/services/requests/card'

const credential = {
  id: ref(''),
  start: ref(''),
  end: ref(''),
}

export const generateCard = () => {
  const loading = ref(false)
  const error = ref('')

  const setGenerateCardId = (id: string) => {
    credential.id.value = id
    useCardsModal().openGenerateStatement()
  }

  const disabled = computed(() => {
    if (credential.start.value === '' || credential.end.value === '') return true
    else return false
  })

  const generate = async () => {
    loading.value = true

    const res = (await cardApi.$_getCardStatement({
      id: credential.id.value,
      start: credential.start.value,
      end: credential.end.value,
    })) as any

    fileDownload(res?.data, 'Card Statement.csv')
    loading.value = false

    if (res !== 'ERROR') {
      useCardsModal().closeGenerateStatement()
      useAlert().openAlert({ type: 'SUCCESS', msg: res?.data?.message })
      credential.start.value = ''
      credential.end.value = ''
    }
  }
  return { credential, generate, loading, error, disabled, setGenerateCardId }
}
