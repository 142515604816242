import { object, string, mixed, boolean } from 'yup'
import { CurrencyCode, EmploymentStatus, ProofOfAddress, SourceOfFunds, UsResidencyStatus } from '~/src/types/internals'

const associate = {
  associate: object().shape({
    id: string().required().label('director'),
    email: string().email().required().label('email'),
    dob: string().required().label('date of birth'),
    identificationNumber: string().required().label('identification number'),
    passportNumber: string().required().label('passport number'),
    nationality: string().required().label('nationality'),
    usResidencyStatus: mixed<UsResidencyStatus>()
      .oneOf(Object.values(UsResidencyStatus))
      .required()
      .label('residency status'),
    employmentStatus: mixed<EmploymentStatus>()
      .oneOf(Object.values(EmploymentStatus))
      .required()
      .label('employment status'),
    employmentDescription: string().required().label('employment description'),
    employerName: string().required().label('employment name'),
    occupation: string().required().label('occupation'),
    documents: object({
      sourceOfFunds: object({
        fileName: mixed<SourceOfFunds>().oneOf(Object.values(SourceOfFunds)).required(),
        file: string().required().label('bank statment'),
      }), // Todo remove before release
      passportImage: string().required().label('passport image'),
      proofOfAddress: object({
        fileName: mixed<ProofOfAddress>().oneOf(Object.values(ProofOfAddress)).required().label('proof of address'),
        file: string().required().label('address type'),
      }),
    }),
  }),
}
export const associateSchema = object(associate)

const businessUSDDetails = {
  stateOfIncorporation: string().required().label('state of incorporation'),
  currency: mixed<CurrencyCode>().oneOf(Object.values(CurrencyCode)).required(),
  address: object().shape({
    street: string().required().label('street'),
    street2: string(),
    city: string().required().label('city'),
    state: string().required().label('state'),
    postalCode: string().required().label('postal code'),
    country: string().required().label('country'),
  }),
  documents: object({
    sourceOfFunds: object({
      fileName: mixed<SourceOfFunds>().oneOf(Object.values(SourceOfFunds)).required(),
      file: string().required().label('bank statment'),
    }),
    proofOfAddress: object({
      fileName: mixed<ProofOfAddress>().oneOf(Object.values(ProofOfAddress)).required().label('proof of address'),
      file: string().required().label('address type'),
    }),
  }),
}

export const businessUSDDetailsSchema = object(businessUSDDetails)
export const createAccountSchema = object().shape({ ...businessUSDDetails, ...associate })
export const requestAccountSchema = object({
  redirectUrl: string().required(),
  currency: string<CurrencyCode>().oneOf(Object.values(CurrencyCode)).required(),
})

export const requestAccountResponseSchema = object({
  status: boolean().required(),
  message: string().required(),
  data: object({
    kycLink: string().required(),
    tosLink: string().required(),
    kycStatus: string().required(),
    tosStatus: string().required(),
  }),
})
