import { useWalletsModal } from '~/src/composables/core/modals'
import { useAlert } from '~/src/composables/core/useNotification'
import { walletApi } from '~/src/services/walletApi'

const credential = {
  id: ref(''),
  start: ref(''),
  end: ref(''),
}

export const generateWalletStatement = () => {
  const loading = ref(false)
  const error = ref('')

  const setGenerateWalletId = (id: string) => {
    credential.id.value = id
    useWalletsModal().openGenerateWalletStatement()
  }

  const disabled = computed(() => {
    if (credential.start.value === '' || credential.end.value === '') return true
    else return false
  })

  const generate = async () => {
    loading.value = true

    const res = (await walletApi.$_getWalletStatement({
      id: credential.id.value,
      start: credential.start.value,
      end: credential.end.value,
    })) as any

    loading.value = false

    if (res !== 'ERROR') {
      useWalletsModal().closeGenerateWalletStatement()
      useAlert().openAlert({ type: 'SUCCESS', msg: res?.data?.message })
      credential.start.value = ''
      credential.end.value = ''
    }
  }
  return { credential, generate, loading, error, disabled, setGenerateWalletId }
}
