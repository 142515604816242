import { transferApi } from '~/src/services/requests/transfer/usd'
import {
  Recipient,
  RecipientStepOne,
  RecipientStepTwo,
  RecipientResponse,
  USDTransfer,
  USDTransferStepOne,
  USDTransferStepTwo,
  USDTransferStepThree,
  USDTransferStepFour,
  USDTransferResponse,
  USDTrackingResponse,
} from '~/src/services/requests/transfer/usd/type'
import { TransactionChannel } from '~/src/types/internals'

const globalState = reactive<{
  recipients: RecipientResponse[]
  recipient: Partial<Recipient> | null
  recipientStepOne: RecipientStepOne | null
  recipientStepTwo: RecipientStepTwo | null
  loading: Record<string, boolean>
  transfer: Partial<USDTransfer> | null
  transaction: USDTransferResponse['data'] | null
  tracking: USDTrackingResponse['data'] | null
  supportedPaymentRails: TransactionChannel[]
  isNewRecipient: boolean
}>({
  isNewRecipient: false,
  recipients: [],
  recipient: null,
  recipientStepOne: null,
  recipientStepTwo: null,
  transfer: null,
  transaction: null,
  tracking: null,
  supportedPaymentRails: [],
  loading: {
    creatingRecipient: false,
    allRecipients: false,
    transfer: false,
    paymentRails: false,
  },
})

export const useUsdTransfer = () => {
  const reset = () => {
    globalState.recipient = null
    globalState.recipientStepOne = null
    globalState.recipientStepTwo = null
    globalState.transfer = null
    globalState.transaction = null
    globalState.tracking = null
    globalState.isNewRecipient = false
    globalState.supportedPaymentRails = []
    globalState.loading = {
      creatingRecipient: false,
      allRecipients: false,
      transfer: false,
      paymentRails: false,
    }
    globalState.recipients = []
  }

  const createTransferStepOne = (values: USDTransferStepOne) => {
    globalState.transfer = {
      ...values,
    }
  }

  const createTransferStepTwo = (values: USDTransferStepTwo) => {
    globalState.transfer = {
      ...globalState.transfer,
      ...values,
    }
  }

  const createTransferStepThree = ({ amount }: USDTransferStepThree) => {
    globalState.transfer = {
      ...globalState.transfer,
      amount: Number(amount),
    }
  }

  const createTransferStepFour = (values: USDTransferStepFour) => {
    globalState.transfer = {
      ...globalState.transfer,
      ...values,
    }
  }

  const getSupportedRails = async (accountId: string) => {
    globalState.loading.paymentRails = true

    const res = await transferApi.$_getSupportedRails(accountId)

    if (res !== 'ERROR') {
      globalState.supportedPaymentRails = res?.data?.data
    }

    globalState.loading.paymentRails = false
  }

  const initiateTransfer = async (data: USDTransfer, options?: { onSuccess?: () => void }) => {
    globalState.loading.transfer = true

    const res = await transferApi.$_initiateTransfer(data)

    if (res !== 'ERROR') {
      globalState.transaction = res?.data?.data
      options?.onSuccess?.()
    }

    globalState.loading.transfer = false
  }

  const getTransferDetails = async (id: string) => {
    globalState.loading.transfer = true

    const res = await transferApi.$_transactionTracking(id)

    if (res !== 'ERROR') {
      globalState.tracking = res?.data?.data
    }

    globalState.loading.transfer = false
  }

  const createRecipientStepOne = (recipient: RecipientStepOne) => {
    globalState.recipientStepOne = {
      ...recipient,
    }
  }

  const createRecipientStepTwo = (recipient: RecipientStepTwo) => {
    globalState.recipientStepTwo = {
      ...recipient,
    }
  }

  const createRecipient = async (
    recipient: Recipient,
    options?: { onSuccess?: (data?: RecipientResponse) => void; onError?: () => void },
  ) => {
    globalState.loading.creatingRecipient = true

    const res = await transferApi.$_createRecipient(recipient)

    if (res !== 'ERROR') {
      options?.onSuccess?.(res?.data?.data)
      globalState.recipients.push(res?.data?.data)
    }

    globalState.loading.creatingRecipient = false
  }

  const getRecipients = async (accountId: string) => {
    globalState.loading.allRecipients = true
    const res = await transferApi.$_getRecipients(accountId)

    if (res !== 'ERROR') {
      globalState.recipients = res?.data?.data || []
    }

    globalState.loading.allRecipients = false
  }
  return {
    reset,
    createTransferStepOne,
    getTransferDetails,
    createTransferStepTwo,
    createTransferStepThree,
    createRecipientStepOne,
    createRecipient,
    createRecipientStepTwo,
    getRecipients,
    initiateTransfer,
    createTransferStepFour,
    getSupportedRails,
    globalState,
  }
}
