import { countryApi } from '~/src/services/requests/country'
import { City, CountryUnit, State } from '~/src/services/requests/country/type'

export type Country = { text: string; id: string; callingCode: string }
export const countries = ref<Country[]>([])

export const getCountries = () => {
  const loading = ref(true)

  const fetch = async () => {
    loading.value = true

    const res = await countryApi.$_getSupportedCountries()
    if (res?.status) {
      countries.value = res?.data?.data?.map(({ name: text, calling_code: callingCode, code: id }) => ({
        callingCode,
        text, // Deprecate this field
        label: text,
        id,
      }))
    }

    loading.value = false
  }
  return { fetch, loading, countries }
}

const globalState = reactive({
  allCountries: [] as CountryUnit[],
  states: {} as Record<string, State[]>,
  cities: {} as Record<string, City[]>,
})

export const useCountries = () => {
  const loading = reactive({
    allCountries: false,
    states: false,
    cities: false,
  })

  const getCityKey = (countryIso: string, stateIso: string) => `${countryIso}-${stateIso}`

  const getAllCountries = async () => {
    if (globalState.allCountries.length) return

    loading.allCountries = true

    const res = await countryApi.$_getAllCountries()

    if (res !== 'ERROR') {
      globalState.allCountries = res?.data
    }
    loading.allCountries = false
  }

  const getStateByISo = async (iso: string) => {
    if (globalState.states?.[iso]?.length) return

    loading.states = true

    const res = await countryApi.$_getStateByCountryIso(iso)

    if (res !== 'ERROR') {
      globalState.states[iso] = res?.data
    }
    loading.states = false
  }

  const getCities = async (countryIso: string, stateIso: string) => {
    if (globalState.cities?.[getCityKey(countryIso, stateIso)]?.length) return

    loading.cities = true

    const res = await countryApi.$_getCities(countryIso, stateIso)

    if (res !== 'ERROR') {
      globalState.cities[getCityKey(countryIso, stateIso)] = res?.data
    }
    loading.cities = false
  }

  return {
    getAllCountries,
    getStateByISo,
    getCities,
    getCityKey,
    ...toRefs(globalState),
    loading,
  }
}
