import { useAlert } from '../core/useNotification'
import { fxApi } from '~/src/services/fxApi'
import { useWalletsModal } from '~/src/composables/core/modals'
import { getWallet } from '~~/src/composables/apis/wallet'
import { CurrencyCode } from '~/src/types/internals'

const state = reactive({
  loading: false,
  sourceCurrency: null as null | CurrencyCode,
  targetCurrency: CurrencyCode.NGN as null | CurrencyCode,
  source: 1000,
  rate: '',
  target: 0,
  reference: '',
  converting: false,
})

export const useCurrencyExchange = () => {
  const { fetchWallet, loading: WalletLoading, walletData } = getWallet()

  const disabled = computed(() => !state.sourceCurrency || !state.source || !state.targetCurrency)

  watch(
    () => state.sourceCurrency,
    (value, prev) => {
      if (!value) return
      if (value === 'NGN' && state.source < 1000) state.source = 1000
      if (value === state.targetCurrency) state.targetCurrency = prev
      exchange()
    },
  )
  watch(
    () => state.targetCurrency,
    (value, prev) => {
      if (!value) return
      if (value === state.sourceCurrency) state.sourceCurrency = prev
      exchange()
    },
  )
  const setAmount = (amount: number) => {
    state.source = amount
    exchange()
  }

  const reset = () => {
    state.source = 1000
    state.target = 0
    state.rate = ''
    state.reference = ''
    state.sourceCurrency = null
    state.targetCurrency = null
  }

  const exchange = async () => {
    state.loading = true
    const res = (await fxApi.$_GeneralQuote({
      source_currency: state.sourceCurrency,
      target_currency: state.targetCurrency,
      amount: state.source,
    })) as any
    state.loading = false

    if (res !== 'ERROR') {
      state.target = res?.data?.data?.target.amount
      state.rate = res?.data?.data?.rate
      state.reference = res?.data?.data?.reference
    }
  }

  const convertCurrency = async () => {
    state.converting = true
    const res = (await fxApi.$_ExchangeCurrency({
      quote_reference: state.reference,
    })) as any

    state.converting = false
    if (res !== 'ERROR') {
      fetchWallet()
      exchange()
      useWalletsModal().closeAll()
      useAlert().openAlert({ type: 'SUCCESS', msg: res?.data?.message })
    }
  }

  return { exchange, state, disabled, reset, convertCurrency, fetchWallet, WalletLoading, setAmount, walletData }
}
