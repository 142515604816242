import { AxiosResponse } from 'axios'
import Recase from 'better-recase'
import { API_ROUTES } from '../../../routes'
import { GATEWAY_ENDPOINT } from '../../SetupBase'
import { IpAddress, IpAddressResponse } from './type'
import { ServerResponse } from '~/src/types/api'

export const ipAddressApi = {
  $_getAddresses: (): Promise<AxiosResponse<IpAddressResponse>> | 'ERROR' => {
    const url = API_ROUTES.ipWhitelist
    return GATEWAY_ENDPOINT.get(url, {
      transformResponse: (data) => Recase.camelCopy(JSON.parse(data)),
    })
  },
  $_addAddress: (
    payload: Omit<IpAddress, 'id' | 'createdAt'>,
  ): Promise<AxiosResponse<ServerResponse<IpAddress>>> | 'ERROR' => {
    const url = API_ROUTES.ipWhitelist
    return GATEWAY_ENDPOINT.post(url, Recase.snakeCopy(payload))
  },
  $_editAdress: (
    payload: Omit<IpAddress, 'createdAt'>,
  ): Promise<Promise<AxiosResponse<ServerResponse<IpAddress>>> | 'ERROR'> => {
    const url = API_ROUTES.ipWhitelist
    return GATEWAY_ENDPOINT.put(url, Recase.snakeCopy(payload))
  },
  $_deleteAdress: async (id: string) => {
    const url = API_ROUTES.ipWhitelist + `/${id}`
    const res = await GATEWAY_ENDPOINT.delete(url)

    return res?.data
  },
}
