<template>
  <Modal
    modal="$atts.modal"
    :title="$t('cards.createBusinessCard')"
    @close="resetForm"
  >
    <form
      class="flex flex-col gap-6 mt-5"
      @submit.prevent="onSubmit"
    >
      <FormControl
        :label="$t('cards.cardName')"
        v-bind="nameProps"
      >
        <InputField
          v-model="name"
          placeholder="John Doe"
        />
      </FormControl>

      <div>
        <CurrencyInput
          id="business-card-currency"
          v-model="amount"
          v-model:selected="currency"
          :select-props="{ disabled: true }"
          :label="`${$t('amount')} ${$t('optional')}`"
          placeholder="0.00"
          :options="CURRENCY_CODE"
        />
        <Typography
          variant="xs"
          color="text-greyDark"
          class="inline-flex gap-1 mt-1"
        >
          <Icon
            name="info"
            class="w-4"
            aria-hidden
          />
          {{ $t('cards.amountDesc', { walletcurrency: currency }) }}
        </Typography>
      </div>

      <FormControl
        :label="$t('cards.selectCardProvider')"
        v-bind="brandProps"
      >
        <Select
          v-model="brand"
          class="w-full"
          size="large"
        >
          <SelectOption
            v-for="item in CARD_BRANDS"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            {{ item.name }}

            <IconsCheckOutlined
              v-if="brand === item.id"
              class="text-success900 h-4 w-4"
            />
          </SelectOption>
        </Select>
      </FormControl>

      <button
        class="btn-primary mt-2"
        :disabled="loading"
      >
        <span v-if="!loading"> {{ $t('create') }}</span> <Spinner v-else />
      </button>
    </form>
  </Modal>
</template>

<script lang="ts" setup>
import { toTypedSchema } from '@vee-validate/yup'
import { useForm } from 'vee-validate'
import Recase from 'better-recase'
import { useCardsModal } from '~/src/composables/core/modals'
import { useAlert } from '~/src/composables/core/useNotification'
import { CARD_BRANDS } from '~/src/constants'
import { cardApi } from '~/src/services/requests/card/index'
import { CardBrand, CurrencyCode, CardType } from '~/src/types/internals'
import { CURRENCY_CODE } from '~/src/constants/index'
import { businessCardSchema } from '~/src/services/requests/card/schema'
import { SnakifyKeys } from '~/src/types/utilities'
import { BusinessCard } from '~/src/services/requests/card/type'
const schema = toTypedSchema(businessCardSchema)

const { defineField, handleSubmit, resetForm } = useForm({
  validationSchema: schema,
  initialValues: {
    brand: CardBrand.VISA,
    currency: CurrencyCode.USD,
    autoApprove: true,
    type: CardType.VIRTUAL,
  },
})

const elPlusConfig = (state: any) => ({
  props: {
    validateEvent: false,
    error: state.errors[0],
    required: state.required,
  },
})

const [name, nameProps] = defineField('name', elPlusConfig)
const [brand, brandProps] = defineField('brand', elPlusConfig)
const [currency] = defineField('currency', elPlusConfig)
const [amount] = defineField('amount', elPlusConfig)

const loading = ref(false)

const onSubmit = handleSubmit(async (values) => {
  loading.value = true

  const formatted = Recase.snakeCopy(values) as unknown as SnakifyKeys<BusinessCard>
  const res = await cardApi.$_createBusinessCard(formatted)

  if (res?.status) {
    useCardsModal().closeCreateBusinessCard()
    useAlert().openAlert({ type: 'SUCCESS', msg: res?.data?.message })
    useRouter().push('/cards')
  }

  loading.value = false
})
</script>
