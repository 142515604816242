import Recase from 'better-recase'
import { useAlert } from '../core/useNotification'
import { useAccountsModal } from '../core/modals'
import { accountApi } from '~/src/services/accountApi'
import { SnakifyKeys } from '~/src/types/utilities'

export const createAccount = () => {
  // const Router = useRouter()
  const credential = {
    customer_id: ref(''),
    currency: ref('NGN'),
  }

  const disabled = computed(() => {
    if (credential.customer_id.value === '' || credential.currency.value === '') return true
    else return false
  })

  const loading = ref(false)
  const create = async () => {
    loading.value = true
    const res = (await accountApi.$_createAccount({
      customer_id: credential.customer_id.value,
      currency: credential.currency.value,
    })) as any
    loading.value = false
    if (res !== 'ERROR') {
      useAccountsModal().closeCreateAccounts()
      useAlert().openAlert({ type: 'SUCCESS', msg: res?.data?.message })
    }
  }

  const updateLanguage = async (preferredLanguage: string, options?: { onSuccess?: () => void }) => {
    loading.value = true

    const formatted = Recase.snakeCopy({ preferredLanguage }) as unknown as SnakifyKeys<{
      preferredLanguage: string
    }>
    const res = await accountApi.$_updatePreferredLang(formatted)
    loading.value = false
    if (res.status) {
      options?.onSuccess?.()
    } else {
      useAlert().openAlert({ type: 'ERROR', msg: 'Unable to update language' })
    }
  }
  return { credential, create, updateLanguage, loading, disabled }
}
