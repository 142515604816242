import { fetchWallet } from './wallet'
import { useAlert } from '~/src/composables//core/useNotification'
import { walletApi } from '~/src/services/walletApi'

export const editLimitModalType = ref('')

export const editLimit = () => {
  const loading = ref(false)
  const error = ref('')
  const amount = ref()

  const edit = async (balance: number, options?: { onSuccess?: () => void }) => {
    loading.value = true

    const res = (await walletApi.$_editLimit(editLimitModalType.value, {
      balance,
    })) as any

    loading.value = false

    if (res !== 'ERROR') {
      useAlert().openAlert({ type: 'SUCCESS', msg: res?.data?.message })
      options?.onSuccess?.()

      fetchWallet()
    }
  }
  return { amount, editLimitModalType, edit, loading, error }
}
