<script lang="ts" setup>
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { useWhitelist } from '~/src/composables/apis/ipWhitelist'
import { isValidIPv4v6 } from '~/src/helpers'

const schema = toTypedSchema(
  yup.object({
    name: yup.string().required().label('Role name'),
    ipAddress: yup
      .string()
      .required()
      .test('is-ip-address', 'Enter valid IP address', (value) => isValidIPv4v6(value))
      .label('IP address'),
  }),
)

const { state, addAddress, editAddress, resetState, editing, creating } = useWhitelist()
const { defineField, handleSubmit, resetForm, isSubmitting, setFieldValue } = useForm({
  validationSchema: schema,
})

const elPlusConfig = (state: any) => ({
  props: {
    validateEvent: false,
    error: state.errors[0],
    required: state.required,
  },
})

const [name, nameProps] = defineField('name', elPlusConfig)
const [ipAddress, ipAddressProps] = defineField('ipAddress', elPlusConfig)

const reset = () => {
  resetState()
  resetForm()
}

const onSubmit = handleSubmit(async (values) => {
  state.mode === 'edit' ? await editAddress(state.address.id!, values) : await addAddress(values)
})

onMounted(() => {
  if (state.mode === 'edit') {
    setFieldValue('ipAddress', state.address.ipAddress)
    setFieldValue('name', state.address.name)
  }
})
</script>
<template>
  <Modal
    :title="$t('settings.addIP')"
    closable
    @close="reset"
  >
    <form
      class="w-full"
      @submit.prevent="onSubmit"
    >
      <div class="flex flex-col gap-6 mt-4 mb-8">
        <FormControl
          :label="$t('settings.ipName')"
          v-bind="nameProps"
        >
          <InputField
            v-model="name"
            placeholder="Server 1"
          />
        </FormControl>

        <FormControl
          :label="$t('settings.ipAddress')"
          v-bind="ipAddressProps"
        >
          <InputField
            v-model="ipAddress"
            placeholder="0.0.0.0"
          />
        </FormControl>
      </div>
      <Button
        class="w-full"
        size="large"
        :loading="creating || editing || isSubmitting"
      >
        {{ $t('submit') }}
      </Button>
    </form>
  </Modal>
</template>
