import { Permission } from '~/src/types/internals'
import { useSettingsModal } from '~/src/composables/core/modals'
import { useAlert } from '~/src/composables/core/useNotification'
import { Invite, PostRole } from '~/src/types/api'
import { useBusiness } from '~/src/composables/apis/business'
import rolesApi from '~/src/services/requests/roles'
import { TransformedRole, User } from '~/src/services/requests/roles/type'

export const globalState = reactive<{
  permissions: Permission[]
  loadingPermisions: boolean
  roles: TransformedRole[]
  loading: boolean
  team: User[]
  loadingTeam: boolean
  currentUser: null | User
}>({
  loadingPermisions: false,
  permissions: [],
  roles: [],
  team: [],
  loading: false,
  loadingTeam: false,
  currentUser: null,
})

export const useRoles = () => {
  const state = reactive<{
    currentRole: TransformedRole | null
    creating: boolean
    inviting: boolean
    resending: boolean
    removingUser: boolean
    fetchingUser: boolean
  }>({
    currentRole: null,
    creating: false,
    inviting: false,
    resending: false,
    removingUser: false,
    fetchingUser: false,
  })

  const { user, business } = useBusiness()

  const isSelfAdmin = computed(() => {
    const self = globalState.team.find((member: User) => member.email === user.value.email)

    if (!self) {
      return isOwner(user.value.email)
    }

    return isAdmin(self)
  })

  const isOwner = (email: string) => {
    return email === business.value.email
  }

  const isAdmin = (member: User) => {
    return getRoleDetails(member.role)?.isAdmin
  }

  const getRoleDetails = (id: string) => {
    return globalState.roles.find((role) => role.id === id)
  }

  const getRoles = async () => {
    globalState.loading = true
    const res = await rolesApi.$_getRoles()

    if (res !== 'ERROR') {
      globalState.roles = res?.data?.data
    }

    globalState.loading = false
  }

  const getPermissions = async () => {
    globalState.loadingPermisions = true
    const res = await rolesApi.$_getAllPermissions()

    if (res !== 'ERROR') {
      globalState.permissions = res?.data?.data
    }

    globalState.loadingPermisions = false
  }

  const addRole = async (role: PostRole) => {
    state.creating = true
    const res = await rolesApi.$_addRole(role)

    if (res !== 'ERROR') {
      await getRoles()
      useSettingsModal().closeNewRole()
      useAlert().openAlert({ type: 'SUCCESS', msg: res?.data?.message })
    }

    state.creating = false
  }

  const getTeam = async () => {
    globalState.loadingTeam = true
    const res = await rolesApi.$_getTeam()

    if (res !== 'ERROR') {
      globalState.team = res?.data?.data
    }

    globalState.loadingTeam = false
  }

  const getUserById = async (id: string) => {
    state.fetchingUser = true

    let user = globalState.team.find((user) => user.id === id)

    if (!user) {
      const res = await rolesApi.$_getTeam()

      if (res !== 'ERROR') {
        globalState.team = res?.data?.data
        user = globalState.team.find((user) => user.id === id)
      }
    }

    state.fetchingUser = false
    return user
  }

  const sendInvite = async (invite: Invite) => {
    state.inviting = true
    const res = await rolesApi.$_inviteUser(invite)

    if (res !== 'ERROR') {
      await getTeam()
      useSettingsModal().closeNewTeam()
      useAlert().openAlert({ type: 'SUCCESS', msg: res?.data?.message })
    }

    state.inviting = false
  }

  const removeUser = async (id: string) => {
    state.removingUser = true
    const res = await rolesApi.$_removeUser(id)

    if (res !== 'ERROR') {
      globalState.team = globalState.team.filter((member) => member.id !== id)
      useSettingsModal().closeDeleteTeam()
      useAlert().openAlert({ type: 'SUCCESS', msg: res?.data?.message })
    }

    state.removingUser = false
  }

  const resendInvite = async (id: string) => {
    state.resending = true

    const res = await rolesApi.$_reinvite(id)
    if (res !== 'ERROR') {
      useAlert().openAlert({ type: 'SUCCESS', msg: res?.data?.message })
    }

    state.resending = false
  }

  const makeAdmin = async (user: Pick<User, 'id' | 'role' | 'status'>) => {
    let prevRole = ''

    const member = globalState.team.find(({ id }) => id === user.id)
    if (!member) return

    prevRole = member.role
    member.role = user.role
    const res = await rolesApi.$_updateUserPermission(user)

    if (res !== 'ERROR') {
      useAlert().openAlert({ type: 'SUCCESS', msg: res?.data?.message })
      return res
    } else {
      member.role = prevRole
    }
  }

  const { currentRole, creating, inviting, resending, removingUser, fetchingUser } = toRefs(state)

  return {
    currentRole,
    creating,
    inviting,
    resending,
    removingUser,
    fetchingUser,
    state: globalState,
    isSelfAdmin,
    isAdmin,
    getRoleDetails,
    getUserById,
    getRoles,
    getPermissions,
    addRole,
    getTeam,
    sendInvite,
    removeUser,
    resendInvite,
    makeAdmin,
    isOwner,
  }
}
