<template>
  <Modal :title="$t('settings.deleteIp')">
    <form
      class="w-full"
      @submit.prevent="handleSubmit"
    >
      <p class="text-secondary py-6 text-center">
        {{ $t('settings.confirmIpDelete') }}: <strong>{{ state.address.ipAddress }}</strong>
      </p>
      <div class="flex justify-between gap-4">
        <button
          class="btn-secondary-md w-1/2"
          type="button"
          @click="useSettingsModal().closeDeleteIpAddress()"
        >
          {{ $t('cancel') }}
        </button>
        <button class="capitalize btn-primary-md bg-red w-1/2 p-0">
          <Spinner
            v-if="deleting"
            class="!w-5 !h-5"
          />
          <span
            v-else
            class="text-white"
          >
            {{ $t('delete') }}
          </span>
        </button>
      </div>
    </form>
  </Modal>
</template>

<script lang="ts" setup>
import { useSettingsModal } from '~/src/composables/core/modals'
import { useWhitelist } from '~/src/composables/apis/ipWhitelist'

const { state, deleting, deleteAddress } = useWhitelist()

const handleSubmit = () => {
  state.address?.id && deleteAddress(state.address?.id)
}
</script>

<style scoped></style>
