import { useAlert } from '../core/useNotification'
import { useBillsModal } from '../core/modals'
import { billsApi } from '~/src/services/billsApi'
import { usePagination } from '~/src/composables/core/utils'
import { ROUTES_NAMES } from '~/src/routes'

let fetchAirtimeHistory: any
export const buyAirtime = () => {
  const loading = ref(false)
  const error = ref('')
  const credential = {
    phone_number: ref(''),
    amount: ref(''),
    identifier: ref('ng-airtime'),
  }

  const disabled = computed(() => {
    if (
      credential.phone_number.value.length < 9 ||
      credential.amount.value === '' ||
      credential.identifier.value === ''
    )
      return true
    else return false
  })

  // TODO: use new billing api
  const buy = async () => {
    loading.value = true
    const res = (await billsApi.$_buyAirtime({
      phone_number: credential.phone_number.value,
      identifier: credential.identifier.value,
      amount: Number(credential.amount.value),
    })) as any
    loading.value = false

    if (res !== 'ERROR') {
      useBillsModal().closeBuyAirtime()
      useRouter().push({
        name: ROUTES_NAMES.transactions,
      })
      useAlert().openAlert({ type: 'SUCCESS', msg: res?.data?.message })
    }
  }
  return { credential, buy, loading, error, disabled }
}

export const fetchAirtimeBillers = () => {
  const loading = ref(false)
  const billerData = ref([] as any)

  const fetch = async () => {
    loading.value = true
    const res = (await billsApi.$_fetchAirtimeBillers()) as any
    loading.value = false
    if (res !== 'ERROR') {
      res?.data?.data?.forEach((x: any) => {
        billerData.value.push({
          text: x.identifier.split('-')[0],
          id: x.identifier,
        })
      })
    }
  }
  return { fetch, loading, billerData }
}

export const getAirtimeHistory = () => {
  const { metaObject, showLoadMore, loadMore, setFunction } = usePagination()
  const loading = ref(false)
  const AirtimeData = ref([] as any)

  fetchAirtimeHistory = async (updating = false) => {
    loading.value = true
    const res = (await billsApi.$_getAirtimeHistory(metaObject.page.value)) as any
    loading.value = false
    if (res !== 'ERROR') {
      if (updating) {
        AirtimeData.value = [...AirtimeData.value, ...res?.data?.data]
      } else {
        AirtimeData.value = res?.data?.data
      }
      metaObject.total.value = res?.data?.meta?.total
    }
  }

  setFunction(fetchAirtimeHistory)
  return { fetchAirtimeHistory, loading, AirtimeData, showLoadMore, loadMore }
}
