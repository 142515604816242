<template>
  <Modal
    modal="$atts.modal"
    :title="$t('fx.convert')"
  >
    <div class="flex flex-col w-full items-start mt-10">
      <div class="field">
        <span class="text-greyDark">{{ $t('transfer.youAreConverting') }}</span>
        <p class="text-xl font-semibold text-secondary">
          {{ state.sourceCurrency && maCurrencyFormatter(state.sourceCurrency).format(toUnit(state.source)) }}
        </p>
      </div>
      <div class="field mt-4">
        <span class="text-greyDark">{{ $t('to') }}</span>
        <p class="text-xl font-semibold text-secondary">
          {{ state.targetCurrency && maCurrencyFormatter(state.targetCurrency).format(toUnit(state.target)) }}
        </p>
      </div>

      <div class="field mt-8 gap-4">
        <div class="flex w-full justify-between items-center">
          <span class="font-semibold text-sm">{{ $t('transfer.payingFrom') }}</span>
          <span class="text-sm">{{ $t('wallets.currencyWallet', { currency: state.sourceCurrency }) }}</span>
        </div>
        <div class="w-full border-b h-0 border-divider" />
        <div class="flex w-full justify-between items-center">
          <span class="font-semibold text-sm">{{ $t('transfer.recipient') }}</span>
          <span class="text-sm">{{ $t('wallets.currencyWallet', { currency: state.targetCurrency }) }}</span>
        </div>
      </div>
    </div>
    <div class="flex justify-between w-full gap-4 mt-14">
      <button
        class="btn-secondary-md w-full"
        type="button"
        @click="useWalletsModal().closeConvertCurrency()"
      >
        {{ $t('cancel') }}
      </button>
      <button
        class="btn-primary-md w-full"
        :disabled="state.converting"
        @click="convertCurrency"
      >
        <span v-if="!state.converting"> {{ $t('confirm') }}</span> <Spinner v-else />
      </button>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { maCurrencyFormatter } from '@wirepay/maplerad-component-lib'
import { useWalletsModal } from '~/src/composables/core/modals'
import { useCurrencyExchange } from '~/src/composables/apis/currency'
import { toUnit } from '~/src/helpers/fromMinorUnit'

const { state, convertCurrency } = useCurrencyExchange()
</script>

<style scoped>
.slideUp-enter {
  opacity: 0;
  /* transform: translateY(200px); */
}
.slideUp-leave-to {
  opacity: 0;
  /* transform: translateY(-200px); */
}

.slideUp-enter-active,
.slideUp-leave-active {
  transition: all 0.35s ease;
}
</style>
