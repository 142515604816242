import Recase from 'better-recase'
import { useAlert } from '~/src/composables//core/useNotification'
import { useWalletsModal } from '~/src/composables//core/modals'
import { walletApi } from '~/src/services/walletApi'
import { businessApi } from '~/src/services/requests/business'
import { toMinorUnit } from '~/src/helpers/toMinorUnit'
import { VirtualAccountStatus, CurrencyCode } from '~/src/types/internals'
import { CreateAccount, RequestAccountResponse, VirtualAccount, Wallet } from '~/src/services/requests/wallet/types'
import { SnakifyKeys } from '~/src/types/utilities'
// todo: fix mutable export
// eslint-disable-next-line import/no-mutable-exports
export let fetchWallet: any

const globalState = reactive<{
  loading: Record<string, boolean>
  accountDetails: null | VirtualAccount
  loadingUsdAccount: boolean
  amount: string
  currency: CurrencyCode | ''
  requestAccountGateway: null | RequestAccountResponse['data']
}>({
  loading: {
    requestingUSDAccount: false,
  },
  loadingUsdAccount: false,
  amount: '',
  currency: '',
  accountDetails: null,
  requestAccountGateway: null,
})

export const useWallet = () => {
  const requestUsdAccount = async (currency: CurrencyCode, options?: { onSuccess?: () => void }) => {
    globalState.loading.requestingUSDAccount = true
    const res = await businessApi.$_requestBusinessVirtualAccount({
      redirectUrl: `${window.location.origin}/wallets`,
      currency,
    })

    if (res !== 'ERROR') {
      options?.onSuccess?.()

      globalState.requestAccountGateway = res?.data?.data
    }

    globalState.loading.requestingUSDAccount = false
  }

  watch(
    () => globalState.requestAccountGateway,
    (value) => {
      if (value?.kycLink) {
        navigateTo(value?.kycLink, {
          external: true,
        })
      }
    },
  )

  return {
    globalState,
    requestUsdAccount,
  }
}

export const useFundWallet = () => {
  const virtualAccounts = ref<VirtualAccount[]>([])
  const virtualAccountsStatus = ref<VirtualAccountStatus | null>(null)
  const loading = ref(false)
  const loadingStatus = ref(false)
  const creatingAcount = ref(false)
  const error = ref('')

  const usdVirtualAccounts = computed(() =>
    virtualAccounts.value.filter((account) => account.currency === CurrencyCode.USD),
  )

  const disabled = computed(() => {
    if (globalState.amount === '' || globalState.currency === '') return true
    else return false
  })

  const createBusinessVirtualAccount = async (
    directorDetails: CreateAccount,
    options?: { onSuccess?: () => void; onError?: () => void },
  ) => {
    creatingAcount.value = true

    const formatted = Recase.snakeCopy(directorDetails) as unknown as SnakifyKeys<CreateAccount>
    const res = await businessApi.$_createBusinessVirtualAccount(formatted)

    if (res !== 'ERROR') {
      useAlert().openAlert({ type: 'Alert', msg: res?.data?.message })

      globalState.currency && getVirtualAccounts(globalState.currency)
      options?.onSuccess?.()
    }

    creatingAcount.value = false
  }

  const getVirtualAccounts = async (currency: CurrencyCode) => {
    loading.value = true

    const res = (await businessApi.$_getBusinessVirtualAccount(currency)) as any
    if (res !== 'ERROR') {
      virtualAccounts.value = Recase.camelCopy(res?.data?.data) as VirtualAccount[]
    }

    loading.value = false
  }

  const getVirtualAccountById = async (accountId: string) => {
    loading.value = true

    const res = (await businessApi.$_getVirtualAccountById(accountId)) as any
    if (res !== 'ERROR') {
      globalState.accountDetails = res?.data?.data
    }

    loading.value = false
  }

  const getAccountsStatus = async () => {
    loadingStatus.value = true

    const res = await businessApi.$_getBusinessVirtualAccountStatus()
    if (res?.data.status) {
      virtualAccountsStatus.value = Recase.camelCopy(res?.data?.data.status)
    }

    loadingStatus.value = false
  }

  const fund = async () => {
    loading.value = true
    const res = (await walletApi.$_fundWallet({
      currency: globalState.currency,
      amount: toMinorUnit(globalState.amount),
    })) as any
    loading.value = false

    if (res !== 'ERROR') {
      useWalletsModal().closeFundWallet()
      useAlert().openAlert({ type: 'SUCCESS', msg: res?.data?.message })
      fetchWallet()
    }
  }
  return {
    getVirtualAccounts,
    getVirtualAccountById,
    usdVirtualAccounts,
    virtualAccounts,
    fund,
    loading,
    error,
    disabled,
    creatingAcount,
    state: globalState,
    loadingStatus,
    virtualAccountsStatus,
    createBusinessVirtualAccount,
    getAccountsStatus,
  }
}

export const getWallet = () => {
  const loading = ref(false)
  const walletData = ref<Wallet[]>([])

  fetchWallet = async () => {
    loading.value = true
    const res = await walletApi.$_getWallet()

    loading.value = false
    if (res !== 'ERROR') {
      walletData.value = res?.data?.data.sort((a, b) => {
        if (a.availableBalance > b.availableBalance) return -1
        if (a.availableBalance < b.availableBalance) return 1
        return 0
      })
    }
  }
  return { fetchWallet, loading, walletData }
}
export const getWalletHistoriesById = () => {
  const loading = ref(false)
  const walletHistoryData = ref([])

  const fetchWalletHistoriesById = async (id: string) => {
    loading.value = true
    const res = (await walletApi.$_getWalletHistoriesByCurrency(id)) as any
    loading.value = false
    if (res !== 'ERROR') {
      walletHistoryData.value = res?.data?.data
    }
  }
  return { fetchWalletHistoriesById, loading, walletHistoryData }
}
