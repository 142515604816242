<template>
  <Modal
    modal="$atts.modal"
    :title="$t('wallets.currencyExchange')"
  >
    <div
      v-if="state.sourceCurrency && state.targetCurrency"
      class="flex flex-col w-full"
    >
      <CurrencyConverterInput
        v-model:currency="state.sourceCurrency"
        :model-value="state.source.toString()"
        :currency-options="CurrencyArray"
        @update:model-value="(value: string) => setAmount(Number(value))"
      />

      <CurrencyConverterInput
        v-model:currency="state.targetCurrency"
        :model-value="state.target.toString()"
        :currency-options="CurrencyArray"
        disabled
      />

      <span class="mt-3 italic font-medium text-tertiary">
        1 {{ state.sourceCurrency }} = {{ state.rate }}
        {{ state.targetCurrency }}
      </span>
    </div>
    <div
      v-else
      class="flex justify-center w-full"
    >
      <Spinner class="text-secondary !border-t-secondary" />
    </div>

    <div class="flex justify-between w-full gap-4 mt-14">
      <button
        class="w-[25%] sm:w-full btn-secondary-md"
        type="button"
        @click="cancel"
      >
        {{ $t('cancel') }}
      </button>
      <button
        class="w-full btn-primary-md"
        :disabled="disabled || state.loading"
        @click="useWalletsModal().openConvertCurrency()"
      >
        <span> {{ $t('next') }}</span>
      </button>
    </div>
  </Modal>
</template>

<script lang="ts" setup>
import { useWalletsModal } from '~/src/composables/core/modals'
import { useCurrencyExchange } from '~/src/composables/apis/currency'
import { CurrencyArray } from '~/src/constants/index'

const { state, reset, setAmount, disabled } = useCurrencyExchange()

const cancel = () => {
  useWalletsModal().closeWalletConverter()
  reset()
}
</script>
