<script lang="ts" setup>
import { useForm } from 'vee-validate'
import { schema } from './type'
import { useRoles } from '~/src/composables/apis/settings/roles'
import { groupByPermissionType } from '~/src/helpers/transformRoles'
import { PermissionType } from '~/src/types/api'
import { Permission } from '~/src/services/requests/roles/type'

const enableAll = ref(false)
const { state, creating, addRole, getPermissions } = useRoles()

// form inputs
const { defineField, handleSubmit, resetForm, isSubmitting } = useForm({
  validationSchema: schema,
})

const elPlusConfig = (state: any) => ({
  props: {
    validateEvent: false,
    error: state.errors[0],
    required: state.required,
  },
})

const [name, nameProps] = defineField('name', elPlusConfig)
const [description, descriptionProps] = defineField('description', elPlusConfig)
const [permissions, permissionsProps] = defineField('permissions', elPlusConfig)

const groupedPermissions = computed(
  () => groupByPermissionType(state.permissions) as Record<PermissionType, Permission[]>,
)
const permissionKeys = computed(() => state.permissions.map(({ key }) => key))

const selectAll = (val: boolean) => {
  permissions.value = val ? permissionKeys.value : []
  enableAll.value = val
}

const onSubmit = handleSubmit(async (values) => {
  await addRole(values)
})

getPermissions()
</script>
<template>
  <Modal
    :title="$t('settings.createCustomRole')"
    @close="resetForm"
  >
    <form
      class="w-full"
      @submit.prevent="onSubmit"
    >
      <div class="max-h-[520px] overflow-scroll flex flex-col gap-6">
        <FormControl
          :label="$t('settings.roleName')"
          v-bind="nameProps"
        >
          <InputField
            v-model="name"
            placeholder="John Doe"
          />
        </FormControl>

        <FormControl
          :label="$t('settings.roleDesc')"
          v-bind="descriptionProps"
        >
          <InputField
            v-model="description"
            :placeholder="$t('settings.describeRole')"
          />
        </FormControl>

        <div>
          <Typography variant="baseMedium"> {{ $t('settings.setPermissions') }} </Typography>

          <Divider />

          <div
            v-if="state.loadingPermisions"
            class="flex-1 flex justify-center items-center"
          >
            <Spinner class="text-secondary !border-t-secondary" />
          </div>

          <template v-else>
            <div class="flex gap-2 items-center">
              <NewSwitch
                :model-value="enableAll"
                size="large"
                :label="$t('settings.enableAllPermissions')"
                @update:model-value="selectAll"
              />
              <Typography
                as="label"
                variant="xs"
              >
                {{ $t('settings.assignAllPermissions') }}
                <!-- (except for transfer of account ownership this can only be done by the account owner) -->
              </Typography>
            </div>

            <Collapse class="mt-3 mb-3">
              <CollapseItem
                v-for="(permission, type) in groupedPermissions"
                :key="type"
                :name="type"
              >
                <template #title>
                  <div class="flex flex-col text-left py-5">
                    <Typography
                      class="capitalize"
                      variant="baseMedium"
                    >
                      {{ $t('settings.permissions', { name: type }) }}
                    </Typography>
                    <Typography
                      color="text-grey"
                      class="mt-1"
                    >
                      {{ $t('settings.canAccessAndManage', { type: type }) }}
                    </Typography>
                  </div>
                </template>

                <FormControl v-bind="permissionsProps">
                  <checkbox-group
                    v-model="permissions"
                    :label="type + 'permissions group'"
                    class="flex flex-col bg-gray100 p-4 rounded-[10px]"
                  >
                    <checkbox
                      v-for="item in permission"
                      :key="item.key"
                      :label="item.key"
                    >
                      <Typography
                        as="span"
                        color="text-greyDark"
                        >{{ item.description }}</Typography
                      >
                    </checkbox>
                  </checkbox-group>
                </FormControl>
              </CollapseItem>
            </Collapse>
          </template>
        </div>
      </div>

      <Button
        size="large"
        class="w-full"
        :disabled="state.loadingPermisions || isSubmitting || creating"
      >
        <Spinner
          v-if="isSubmitting"
          class="!w-5 !h-5"
        />
        <span v-else> {{ $t('settings.createRole') }}</span>
      </Button>
    </form>
  </Modal>
</template>
