import { useSettingsModal } from '../core/modals'
import { ipAddressApi } from '~/src/services/requests/ip-address'
import { IpAddress } from '~/src/services/requests/ip-address/type'

const state = reactive<{ address: Partial<IpAddress>; mode: null | 'edit' | 'add'; addresses: IpAddress[] }>({
  address: {
    id: '',
    ipAddress: '',
    name: '',
    createdAt: undefined,
  },
  mode: null,
  addresses: [],
})

export const useWhitelist = () => {
  const loading = ref(false)
  const creating = ref(false)
  const deleting = ref(false)
  const editing = ref(false)

  const getAddresses = async () => {
    loading.value = true
    const res = await ipAddressApi.$_getAddresses()

    if (res !== 'ERROR') {
      state.addresses = res?.data?.data || []
    }

    loading.value = false
  }

  const resetState = () => {
    state.mode = null
    state.address.id = ''
    state.address.name = ''
    state.address.ipAddress = ''
    state.address.createdAt = undefined
  }

  const addAddress = async ({ ipAddress, name }: { ipAddress: string; name: string }) => {
    creating.value = true

    const res = await ipAddressApi.$_addAddress({ name, ipAddress })

    if (res !== 'ERROR') {
      await getAddresses()
    }

    resetState()
    creating.value = false
    useSettingsModal().closeIpWhitelist()
  }

  const editAddress = async (id: string, { ipAddress, name }: { ipAddress: string; name: string }) => {
    editing.value = true

    const res = await ipAddressApi.$_editAdress({ id, name, ipAddress })

    if (res !== 'ERROR') {
      const index = state.addresses.findIndex((address) => {
        return address.id === state.address.id
      })

      state.addresses[index].ipAddress = ipAddress
      state.addresses[index].name = name
    }

    resetState()
    editing.value = false
    useSettingsModal().closeIpWhitelist()
  }

  const deleteAddress = async (id: string) => {
    deleting.value = true

    const res = await ipAddressApi.$_deleteAdress(id)

    if (res !== 'ERROR') {
      state.addresses = state.addresses.filter((item) => item.id !== id)
    }

    resetState()
    deleting.value = false
    useSettingsModal().closeDeleteIpAddress()
  }

  return {
    getAddresses,
    addAddress,
    deleteAddress,
    editAddress,
    resetState,
    loading,
    creating,
    deleting,
    editing,
    state,
  }
}
