<script setup lang="ts">
import { moneyFormatter } from '~/src/helpers'

interface Option {
  label: string
  value: string
}
interface Props {
  id: string
  label: string
  disabled?: boolean
  selected?: string
  modelValue?: string | number
  hasSelector?: boolean
  maxlength?: string
  options?: Option[]
  placeholder?: string
  selectProps?: {
    placeholder?: string
    disabled?: boolean
    width?: string
  }
  classes?: string
}
withDefaults(defineProps<Props>(), {
  hasSelector: true,
  selected: '',
  modelValue: '',
  options: undefined,
  placeholder: '',
  selectProps: undefined,
  maxlength: undefined,
  classes: '',
})

const emit = defineEmits(['update:modelValue', 'update:selected'])
</script>
<template>
  <SelectInput
    :id="id"
    :label="label"
    :model-value="modelValue"
    :disabled="disabled"
    :maxlength="maxlength"
    type="number"
    :formatter="moneyFormatter"
    :has-selector="hasSelector"
    :select-props="selectProps"
    :selected="selected"
    :classes="classes"
    :options="options"
    :parser="(value: string) => value.replace(/[^\d]/g, '')"
    @update:model-value="(value: string) => emit('update:modelValue', value)"
    @update:selected="$emit('update:selected', $event)"
  />
</template>
