<template>
  <Modal
    :title="$t('transactions.generatePaymentLink')"
    @close="() => resetState()"
  >
    <form
      class="w-full"
      @submit.prevent="handleSubmit"
    >
      <label for="email">{{ $t('email') }}</label>
      <input
        id="email"
        v-model="state.customer_email"
        placeholder="example.mail.com"
        type="text"
        name="email"
        class="input mt-3.5 w-full mb-7"
      />

      <label for="amount">{{ $t('amount') }}</label>
      <input
        id="amount"
        v-model="state.amount"
        placeholder="100"
        type="text"
        name="amount"
        class="input mt-3.5 w-full"
      />
      <p class="text-xs text-secondary mt-1 mb-10">{{ $t('transactions.amountMustBeMore', { amount: 100 }) }}</p>
      <div class="flex justify-between gap-4">
        <button
          class="btn-secondary-md w-1/2"
          type="button"
          @click="close"
        >
          {{ $t('cancel') }}
        </button>
        <button
          class="capitalize btn-primary-md w-1/2 p-0"
          :disabled="!isValidData || loading"
        >
          <Spinner
            v-if="loading"
            class="!w-5 !h-5"
          />
          <template v-else> {{ $t('generate') }}</template>
        </button>
      </div>
    </form>
  </Modal>
</template>

<script lang="ts" setup>
import { useTransactionsModal } from '~/src/composables/core/modals'
import { isValidEmail } from '~/src/helpers/index'
import { checkoutApi } from '~/src/services/checkoutApi'

const loading = ref(false)
const state = reactive({
  amount: '',
  customer_email: '',
  payment_methods: [],
})

const resetState = () => {
  state.amount = ''
  state.customer_email = ''
}

const close = () => {
  resetState()
  useTransactionsModal().closePayment()
}

const isValidData = computed(() => {
  return isValidEmail(state.customer_email) && Number(state.amount) >= 100
})

const handleSubmit = async () => {
  if (!isValidData.value) {
    return
  }

  loading.value = true
  const res = await checkoutApi.$_initialize({ ...state, amount: Number(state.amount) })
  if (res?.status) {
    await navigateTo(`https://checkout.sandbox.maplerad.com/card?reference=${res?.data?.reference}`, {
      external: true,
      open: {
        target: '_blank',
      },
    })
  }
  loading.value = false
  close()
}
</script>

<style scoped></style>
