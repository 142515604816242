import Recase from 'better-recase'
import { institutionApi } from '~/src/services/requests/bank'
import { Bank } from '~/src/services/requests/bank/type'
import { CurrencyCode } from '~/src/types/internals'
import { SnakifyKeys } from '~/src/types/utilities'

export const currencies = ref([] as any)
export const banks = ref<{ name: string; id: string; value: string; label: string }[]>([])
export const countries = ref<Bank[]>([])
export const getCurrencies = () => {
  const loading = ref(true)

  const fetch = async () => {
    loading.value = true
    const res = (await institutionApi.$_getAllCurrencies()) as any
    loading.value = false
    if (res !== 'ERROR') {
      currencies.value = []
      res?.data?.data?.forEach((x: any) => {
        currencies.value.push({
          text: x.currency,
          id: x.currency,
          symbol: x.symbol,
        })
      })
    }
  }
  return { fetch, loading, currencies }
}

export const getBanks = () => {
  const loading = ref(false)

  const fetch = async (type?: string, country?: string) => {
    loading.value = true
    const res = await institutionApi.$_getAllBanks({ type, country })

    if (res?.status) {
      banks.value = res?.data?.data.map(({ name, code }) => ({
        name, // Todo: deprecate
        id: code, // Todo: deprecate
        value: code,
        label: name,
      }))
    }

    loading.value = false
  }
  return { fetch, loading, banks }
}

export const getCountriesByCurrency = () => {
  const loading = ref(false)

  const fetch = async (currency: CurrencyCode) => {
    loading.value = true
    const res = await institutionApi.$_getCountriesByCurrency(currency)

    if (res?.status) {
      countries.value = res?.data?.data
    }

    loading.value = false
  }
  return { fetch, countries, loading }
}

export const resolveAccount = () => {
  const loading = ref(false)
  const resolved = ref('')

  const fetch = async ({ accountNumber, bankCode }: { accountNumber: string; bankCode: string }) => {
    resolved.value = ''
    loading.value = true

    const formatted = Recase.snakeCopy({ accountNumber, bankCode }) as unknown as SnakifyKeys<{
      accountNumber: string
      bankCode: string
    }>

    const res = await institutionApi.$_resolveAccount(formatted)

    resolved.value = res?.status ? res?.data?.data?.account_name : ''

    loading.value = false
  }

  return {
    loading,
    fetch,
    resolved,
  }
}
