import { useSettingsModal } from '../../core/modals'
import { getBusinessesSettings } from './index'
import { useAlert } from '~/src/composables/core/useNotification'
import { businessApi } from '~/src/services/requests/business'

const { fetchBusinessSettings } = getBusinessesSettings()

export const createWebhook = () => {
  const credential = {
    webhook_url: ref(''),
  }

  const loading = ref(false)

  const create = async () => {
    loading.value = true
    const res = (await businessApi.$_createWebhook({
      webhook_url: credential.webhook_url.value,
    })) as any
    if (res !== 'ERROR') {
      useAlert().openAlert({ type: 'SUCCESS', msg: res?.data?.message })
      fetchBusinessSettings()
      useSettingsModal().closeWebhook()
    }
    loading.value = false
  }

  return { credential, create, loading }
}
