<template>
  <el-select
    v-bind="$attrs"
    ref="selectRef"
    class="select"
  >
    <template
      v-for="(_, slot) of $slots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </el-select>
</template>

<script setup>
const props = defineProps({
  autofocus: {
    type: Boolean,
    default: false,
  },
})

const selectRef = ref(null)

onMounted(() => {
  if (props.autofocus) {
    selectRef.value?.focus()
  }
})
</script>

<style>
.select .el-select__wrapper {
  --el-input-border-color: var(--gray10);
  --el-input-focus-border-color: var(--primary);
  --el-input-text-color: var(--text-secondary);
  --el-input-icon-color: var(--grey);
  --el-input-border-radius: 8px;
  @apply rounded-lg border-0;
}
.select.el-select {
  --el-color-primary: var(--primary600);
}
</style>
