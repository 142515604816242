import { ref } from 'vue'
import { useAlert } from '~/src/composables/core/useNotification'
import { useCustomerModal } from '~/src/composables/core/modals'
import { usePagination } from '~/src/composables/core/utils'
import { customerApi } from '~/src/services/customerApi'

let credential = {
  first_name: ref(),
  last_name: ref(),
  email: ref(),
  phone_number: ref(),
  identification_number: ref(),
  dob: ref(),
  identity: {
    type: ref('NIN'),
    number: ref(),
    image: ref('null'),
    country: ref('NG'),
  },
  address: {
    street: ref(),
    street2: ref(),
    city: ref(),
    state: ref(),
    country: ref('NG'),
    postal_code: ref(),
  },
}

const filterData = {
  search: ref(''),
  start_date: ref(''),
  end_date: ref(''),
  status: ref(''),
} as any
const loading = ref(false)
const customersData = ref([] as any[])

// eslint-disable-next-line import/no-mutable-exports
export let fetchCustomers: any
const step = ref(1)

export const createCustomer = () => {
  const loading = ref(false)
  const create = async () => {
    loading.value = true
    const res = (await customerApi.$_createCustomer({
      first_name: credential.first_name.value,
      last_name: credential.last_name.value,
      email: credential.email.value,
      identification_number: credential.identification_number.value,
      phone: {
        phone_country_code: '+234',
        phone_number: credential.phone_number.value,
      },
      dob: credential.dob.value.split('-').reverse().join('-'),
      identity: {
        type: credential.identity.type.value,
        number: credential.identity.number.value.toString(),
        image: credential.identity.image.value,
        country: credential.identity.country.value,
      },
      address: {
        street: credential.address.street.value,
        street2: credential.address.street2.value,
        city: credential.address.city.value,
        state: credential.address.state.value,
        country: credential.address.country.value,
        postal_code: credential.address.postal_code.value,
      },
    })) as any
    loading.value = false
    if (res !== 'ERROR') {
      fetchCustomers()
      useCustomerModal().closeCreateCustomer()
      step.value = 1
      resetForm()
      useAlert().openAlert({ type: 'SUCCESS', msg: res?.data?.message })
    }
  }
  return { credential, create, loading, step }
}

export const getAllCustomers = () => {
  const customersIdData = ref([] as any[])
  const { metaObject, showLoadMore, loadMore, setFunction } = usePagination()

  fetchCustomers = async (updating = false) => {
    loading.value = true
    const res = (await customerApi.$_getAllCustomers(metaObject.page.value, { ...filterData })) as any
    loading.value = false
    if (res !== 'ERROR') {
      if (updating) {
        customersData.value = [...customersData.value, ...res?.data?.data]
        customersIdData.value = [...customersIdData.value, ...res?.data?.data.map((item: any) => item.id)]
      } else {
        customersData.value = res?.data?.data
        customersData.value.forEach((data) => {
          customersIdData.value.push({
            name: `${data.first_name} ${data.last_name}`,
            id: data.id,
          })
        })
      }

      metaObject.total.value = res?.data.meta.total
    }
  }

  setFunction(fetchCustomers)

  return { fetchCustomers, loading, customersData, customersIdData, showLoadMore, loadMore, filterData }
}

export const customerInfo = ref('') as any

const resetForm = () => {
  credential = {
    first_name: ref(),
    last_name: ref(),
    email: ref(),
    phone_number: ref(),
    identification_number: ref(),
    dob: ref(),
    identity: {
      type: ref('BVN'),
      number: ref(),
      image: ref('null'),
      country: ref('NG'),
    },
    address: {
      street: ref(),
      street2: ref(),
      city: ref(),
      state: ref(),
      country: ref('NG'),
      postal_code: ref(),
    },
  }
}
