import { Permission, Role, TransformedRole } from '../services/requests/roles/type'
import { PermissionType } from '../types/api'
import { groupBy } from './groupBy'

export const removeParentPermission = (perm: Permission[]) => {
  return perm.filter(({ key }) => !key.includes('-*'))
}

export const groupByPermissionType = (data: Permission[]): Record<PermissionType, Permission[]> => {
  return groupBy<Permission>(data, ({ type }: Permission) => type)
}

export const transformRoles = (roles: Role[]): TransformedRole[] => {
  return roles.map(({ permissions, ...rest }) => ({
    ...rest,
    permissions: groupByPermissionType(removeParentPermission(permissions)),
  }))
}
