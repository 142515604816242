import { AxiosResponse } from 'axios'
import { SnakifyKeys } from '../types/utilities'
import { Lang, ServerResponse } from '../types/api'
import { AUTH_GATEWAY_ENDPOINT, GATEWAY_ENDPOINT } from './SetupBase'

export const accountApi = {
  $_createAccount: (credential: any) => {
    const url = '/users'
    return GATEWAY_ENDPOINT.post(url, credential)
  },
  $_updatePreferredLang: async (lang: SnakifyKeys<Lang>) => {
    const url = '/users'
    const res: AxiosResponse<ServerResponse<any>> = await AUTH_GATEWAY_ENDPOINT.put(url, lang)
    return res?.data
  },
}
