import { AxiosResponse } from 'axios'
import Recase from 'better-recase'
import { GATEWAY_ENDPOINT } from '../../SetupBase'
import { Permission, TransformedRoleResponse, User } from './type'
import { PostRole, ServerResponse, Invite } from '~/src/types/api'
import { removeParentPermission, transformRoles } from '~/src/helpers/transformRoles'

const rolesApi = {
  $_getRoles: (): Promise<AxiosResponse<TransformedRoleResponse>> | 'ERROR' => {
    const url = '/roles'
    return GATEWAY_ENDPOINT.get(url, {
      transformResponse: (data) => {
        const res = Recase.camelCopy(JSON.parse(data))

        return {
          ...res,
          data: res?.data ? transformRoles(res?.data) : [],
        }
      },
    })
  },
  $_getAllPermissions: (): Promise<AxiosResponse<ServerResponse<Permission[]>>> | 'ERROR' => {
    const url = '/roles/permission'
    return GATEWAY_ENDPOINT.get(url, {
      transformResponse: (data) => {
        const res = Recase.camelCopy(JSON.parse(data))

        return {
          ...res,
          data: removeParentPermission(data),
        }
      },
    })
  },
  $_addRole: (role: PostRole): Promise<AxiosResponse<ServerResponse<any>>> | 'ERROR' => {
    const url = '/roles'
    return GATEWAY_ENDPOINT.post(url, role)
  },
  $_getTeam: (): Promise<AxiosResponse<ServerResponse<User[]>> | 'ERROR'> => {
    const url = '/users'
    return GATEWAY_ENDPOINT.get(url, {
      transformResponse: (data) => Recase.camelCopy(JSON.parse(data)),
    })
  },
  $_inviteUser: (invite: Invite): Promise<AxiosResponse<ServerResponse<any>>> | 'ERROR' => {
    const url = '/users/invites'

    return GATEWAY_ENDPOINT.post(url, invite)
  },
  $_updateUserPermission: (
    user: Pick<User, 'id' | 'role' | 'status'>,
  ): Promise<AxiosResponse<ServerResponse<any>>> | 'ERROR' => {
    const url = '/users'

    return GATEWAY_ENDPOINT.patch(url, user)
  },
  $_removePendingUser: (id: string): Promise<AxiosResponse<ServerResponse<any>>> | 'ERROR' => {
    const url = '/users/invite'

    return GATEWAY_ENDPOINT.delete(`${url}/${id}`)
  },
  $_removeUser: (id: string): Promise<AxiosResponse<ServerResponse<any>>> | 'ERROR' => {
    const url = '/users'

    return GATEWAY_ENDPOINT.delete(`${url}/${id}`)
  },
  $_reinvite: (id: string): Promise<AxiosResponse<ServerResponse<any>>> | 'ERROR' => {
    const url = '/users/re-invite'

    return GATEWAY_ENDPOINT.put(url, {
      id,
    })
  },
}

export default rolesApi
