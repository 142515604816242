import Recase from 'better-recase'
import { transferApi } from '~/src/services/requests/transactions/index'
import { useAlert } from '~/src/composables/core/useNotification'
import { ROUTES_NAMES } from '~/src/routes'
import { MomoTransfer, SnakeMomoTransfer } from '~/src/services/requests/transfers/types'

export const useMobileMoneyTransfer = () => {
  const loading = ref(false)

  const makeTransfer = async (data: MomoTransfer, options: { onSuccess?: () => void }) => {
    loading.value = true

    const formatted = Recase.snakeCopy(data) as unknown as SnakeMomoTransfer

    const res = await transferApi.$_createTransfer(formatted)

    if (res?.status) {
      useAlert().openAlert({ type: 'SUCCESS', msg: res?.data?.message })
      options?.onSuccess?.()

      useRouter().push({
        name: ROUTES_NAMES.transactions,
      })
    }

    loading.value = false
  }

  return {
    loading,
    makeTransfer,
  }
}
